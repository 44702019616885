import Vue from 'vue'
import App from './App.vue'
import router from './router'
import VueGtag from "vue-gtag";

import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import './assets/scss/style.scss'

window.axios = require('axios');
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

Vue.use(VueGtag, {
  config: { id: "G-GYCL7P50KV" }
});

Vue.use(BootstrapVue)
Vue.use(IconsPlugin)

Vue.config.productionTip = false

new Vue({
    router,
    render: function (h) { return h(App) }
}).$mount('#app')
