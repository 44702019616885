<template>
    <b-container class="cta" fluid>
        <b-container>
            <b-row>
                <b-col cols="12" md="9"><h3>{{ text }}</h3></b-col>
                <b-col cols="12"><b-button variant="secondary" :to="{ name: btnRef }" size="lg">{{ btnText }}</b-button></b-col>
            </b-row>
        </b-container>
    </b-container>
</template>

<script>
export default {
    name: 'CallComponent',
    props: [ 'text', 'btnText', 'btnRef' ]
}
</script>

<style lang="scss" scoped>
.cta {
    background:#e9e9e9;
    padding:2rem 0;

    .row {
        text-align: center;

        .col-12:first-of-type {
            margin: 0 auto;
        }

        .btn {
            font-size: .95rem;
            padding: 10px 20px;
            margin-top: 20px;
        }
    }
}
</style>
