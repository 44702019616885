<template>
    <b-img :src="logo" :height="height" />
</template>

<script>
export default {
    name: 'LogoComponent',
    props: [ 'width', 'height', 'variant' ],
    data () {
        return {
            logo: require('./../assets/logo-' + this.variant + '.svg')
        }
    },
    computed: {
    }
}
</script>