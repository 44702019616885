<template>
    <div class="home">
        <div class="banner">
            <particles :height="'80vh'" :width="'100%'"></particles>
            <b-container class="text">
                <h2>Sistemas e Aplicativos desenvolvidos sob medida para as necessidades da sua empresa</h2>
            </b-container>
        </div>

        <div class="svg-border">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 144.54 17.34" preserveAspectRatio="none" fill="currentColor"><path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0"></path></svg>
        </div>

        <b-container class="squares">
            <b-row>
                <b-link class="square col-12 col-md-6 col-lg-3" href="">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="sitemap" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="svg-inline--fa fa-sitemap fa-w-20 fa-3x"><path fill="currentColor" d="M608 352h-32v-97.59c0-16.77-13.62-30.41-30.41-30.41H336v-64h48c17.67 0 32-14.33 32-32V32c0-17.67-14.33-32-32-32H256c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h48v64H94.41C77.62 224 64 237.64 64 254.41V352H32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32H96v-96h208v96h-32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32h-32v-96h208v96h-32c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h96c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32zm-480 32v96H32v-96h96zm240 0v96h-96v-96h96zM256 128V32h128v96H256zm352 352h-96v-96h96v96z" class=""></path></svg>
                            <h4>Soluções <span class="th-green-text">Personalizadas</span></h4>
                        </div>
                        <div class="flip-card-back">
                            <p>Desenvolvimento de soluções sob medida, pensadas e desenvolvidas com base nas necessidades da sua empresa.</p>
                            <b-button :to="{ name: 'Contato' }" variant="outline-light">Mais Informações</b-button>
                        </div>
                    </div>
                </b-link>
                <b-link class="square col-12 col-md-6 col-lg-3" href="">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="briefcase" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-briefcase fa-w-16 fa-3x"><path fill="currentColor" d="M464 128H352V56c0-13.26-10.74-24-24-24H184c-13.26 0-24 10.74-24 24v72H48c-26.51 0-48 21.49-48 48v256c0 26.51 21.49 48 48 48h416c26.51 0 48-21.49 48-48V176c0-26.51-21.49-48-48-48zM192 64h128v64H192V64zm288 368c0 8.82-7.18 16-16 16H48c-8.82 0-16-7.18-16-16V288h160v40c0 13.25 10.75 24 24 24h80c13.25 0 24-10.75 24-24v-40h160v144zM224 320v-32h64v32h-64zm256-64H32v-80c0-8.82 7.18-16 16-16h416c8.82 0 16 7.18 16 16v80z" class=""></path></svg>
                            <h4>Sistema Comecial <span class="th-green-text">ELEVE</span></h4>
                        </div>
                        <div class="flip-card-back">
                            Sistema Comercial pronto para emissão de NF-e, NFC-e e CF-e e uma gestão completa da sua empresa.
                            <b-button :to="{ name: 'Eleve' }" variant="outline-light">Mais Informações</b-button>
                        </div>
                    </div>
                </b-link>
                <b-link class="square col-12 col-md-6 col-lg-3" href="">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <svg aria-hidden="true" focusable="false" data-prefix="fal" data-icon="shield-check" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" class="svg-inline--fa fa-shield-check fa-w-16 fa-3x"><path fill="currentColor" d="M466.5 83.7l-192-80a48.15 48.15 0 0 0-36.9 0l-192 80C27.7 91.1 16 108.6 16 128c0 198.5 114.5 335.7 221.5 380.3 11.8 4.9 25.1 4.9 36.9 0C360.1 472.6 496 349.3 496 128c0-19.4-11.7-36.9-29.5-44.3zM262.2 478.8c-4 1.6-8.4 1.6-12.3 0C152 440 48 304 48 128c0-6.5 3.9-12.3 9.8-14.8l192-80c3.9-1.6 8.4-1.6 12.3 0l192 80c6 2.5 9.9 8.3 9.8 14.8.1 176-103.9 312-201.7 350.8zm136.2-325c-4.7-4.7-12.3-4.7-17-.1L218 315.8l-69-69.5c-4.7-4.7-12.3-4.7-17-.1l-8.5 8.5c-4.7 4.7-4.7 12.3-.1 17l85.9 86.6c4.7 4.7 12.3 4.7 17 .1l180.5-179c4.7-4.7 4.7-12.3.1-17z" class=""></path></svg>
                            <h4>Garantia de <span class="th-green-text">Segurança</span></h4>
                        </div>
                        <div class="flip-card-back">
                            Preocupação e atenção especial com a segurança, em conformidade com a nova Lei Geral de Proteção de Dados.
                            <b-button :to="{ name: 'Eleve' }" variant="outline-light">Mais Informações</b-button>
                        </div>
                    </div>
                </b-link>
                <b-link class="square col-12 col-md-6 col-lg-3" href="">
                    <div class="flip-card-inner">
                        <div class="flip-card-front">
                            <svg aria-hidden="true" focusable="false" data-prefix="far" data-icon="lightbulb-on" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512" class="svg-inline--fa fa-lightbulb-on fa-w-20 fa-3x"><path fill="currentColor" d="M112,192a24,24,0,0,0-24-24H24a24,24,0,0,0,0,48H88A24,24,0,0,0,112,192Zm-4.92,95.22-55.42,32a24,24,0,1,0,24,41.56l55.42-32a24,24,0,0,0-24-41.56Zm24-232-55.42-32a24,24,0,1,0-24,41.56l55.42,32a24,24,0,1,0,24-41.56ZM520.94,100a23.8,23.8,0,0,0,12-3.22l55.42-32a24,24,0,0,0-24-41.56l-55.42,32a24,24,0,0,0,12,44.78ZM616,168H552a24,24,0,0,0,0,48h64a24,24,0,0,0,0-48ZM588.34,319.23l-55.42-32a24,24,0,1,0-24,41.56l55.42,32a24,24,0,0,0,24-41.56ZM320,0C217.72,0,144,83,144,176a175,175,0,0,0,43.56,115.78c16.63,19,42.75,58.8,52.41,92.16V384h48v-.12a47.67,47.67,0,0,0-2.13-14.07C280.25,352,263,305.06,223.66,260.15A127.48,127.48,0,0,1,192.06,176C191.84,102.36,251.72,48,320,48a127.91,127.91,0,0,1,96.34,212.15c-39.09,44.61-56.4,91.47-62.09,109.46A56.78,56.78,0,0,0,352,383.92V384h48V384c9.69-33.37,35.78-73.18,52.41-92.15A175.93,175.93,0,0,0,320,0Zm0,80a96.11,96.11,0,0,0-96,96,16,16,0,0,0,32,0,64.08,64.08,0,0,1,64-64,16,16,0,0,0,0-32ZM240.06,459.19a16,16,0,0,0,2.69,8.84l24.5,36.83A16,16,0,0,0,280.56,512h78.85a16,16,0,0,0,13.34-7.14L397.25,468a16.2,16.2,0,0,0,2.69-8.84L400,416H240Z" class=""></path></svg>
                            <h4>Inovação e <span class="th-green-text">Desafios</span></h4>
                        </div>
                        <div class="flip-card-back">
                            Acreditamos em projetos inovadores e desafiadores, entre em contato, vamos discutir novas ideias.
                            <b-button :to="{ name: 'Contato' }" variant="outline-light">Entrar em Contato</b-button>
                        </div>
                    </div>
                </b-link>
            </b-row>
        </b-container>

        <div class="clouds">
            <b-container>
                <b-row>

                    <b-col cols="12" md="6">
                        <h5>Clouds Sistemas</h5>
                        <div class="h2">Somos uma empresa especializada no desenvolvimento de <strong class="th-green-text">softwares para gestão comercial</strong>.</div>
                    </b-col>

                    <b-col cols="12" md="6">
                        <ul>
                            <li>
                                <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M234.8 511.7L196 500.4c-4.2-1.2-6.7-5.7-5.5-9.9L331.3 5.8c1.2-4.2 5.7-6.7 9.9-5.5L380 11.6c4.2 1.2 6.7 5.7 5.5 9.9L244.7 506.2c-1.2 4.3-5.6 6.7-9.9 5.5zm-83.2-121.1l27.2-29c3.1-3.3 2.8-8.5-.5-11.5L72.2 256l106.1-94.1c3.4-3 3.6-8.2.5-11.5l-27.2-29c-3-3.2-8.1-3.4-11.3-.4L2.5 250.2c-3.4 3.2-3.4 8.5 0 11.7L140.3 391c3.2 3 8.2 2.8 11.3-.4zm284.1.4l137.7-129.1c3.4-3.2 3.4-8.5 0-11.7L435.7 121c-3.2-3-8.3-2.9-11.3.4l-27.2 29c-3.1 3.3-2.8 8.5.5 11.5L503.8 256l-106.1 94.1c-3.4 3-3.6 8.2-.5 11.5l27.2 29c3.1 3.2 8.1 3.4 11.3.4z" class=""></path></svg>
                                Mais de 8 anos de experiência no desenvolvimento de <strong class="th-green-text">ferramentas que automatizam os processos empresariais</strong>.
                            </li>
                            <li>
                                <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M569.9 441.1c-.5-.4-22.6-24.2-37.9-54.9 27.5-27.1 44-61.1 44-98.2 0-80-76.5-146.1-176.2-157.9C368.4 72.5 294.3 32 208 32 93.1 32 0 103.6 0 192c0 37 16.5 71 44 98.2-15.3 30.7-37.3 54.5-37.7 54.9-6.3 6.7-8.1 16.5-4.4 25 3.6 8.5 12 14 21.2 14 53.5 0 96.7-20.2 125.2-38.8 9.1 2.1 18.4 3.7 28 4.8 31.5 57.5 105.5 98 191.8 98 20.8 0 40.8-2.4 59.8-6.8 28.5 18.5 71.6 38.8 125.2 38.8 9.2 0 17.5-5.5 21.2-14 3.6-8.5 1.9-18.3-4.4-25zM155.4 314l-13.2-3-11.4 7.4c-20.1 13.1-50.5 28.2-87.7 32.5 8.8-11.3 20.2-27.6 29.5-46.4L83 283.7l-16.5-16.3C50.7 251.9 32 226.2 32 192c0-70.6 79-128 176-128s176 57.4 176 128-79 128-176 128c-17.7 0-35.4-2-52.6-6zm289.8 100.4l-11.4-7.4-13.2 3.1c-17.2 4-34.9 6-52.6 6-65.1 0-122-25.9-152.4-64.3C326.9 348.6 416 278.4 416 192c0-9.5-1.3-18.7-3.3-27.7C488.1 178.8 544 228.7 544 288c0 34.2-18.7 59.9-34.5 75.4L493 379.7l10.3 20.7c9.4 18.9 20.8 35.2 29.5 46.4-37.1-4.2-67.5-19.4-87.6-32.4z" class=""></path></svg>
                                Sempre dispostos a ouvi-lo e auxiliar sua empresa na implantação de uma <strong class="th-green-text">solução confiável e eficaz</strong>.
                            </li>
                        </ul>
                    </b-col>

                </b-row>
            </b-container>
        </div>

        <div class="eleve">
            <b-container>
                <b-row>
                    <b-col class="notebook" cols="12" md="6">
                        <b-img class="mock" fluid :src="images.eleve"></b-img>
                    </b-col>
                    <b-col cols="12" md="6">
                        <h5>Sistema Eleve</h5>
                        <h2>Sistema de gestão empresarial prático, rápido e seguro!</h2>
                        <p>Sistema pronto para <strong class="th-green-text">emissão de notas fiscais e cupons ficais eletrônicos</strong> para diversos segmentos comerciais.</p>
                        <ul>
                            <li>
                                <svg aria-hidden="true" focusable="false" data-icon="palette" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M204.3 5C104.9 24.4 24.8 104.3 5.2 203.4c-37 187 131.7 326.4 258.8 306.7 41.2-6.4 61.4-54.6 42.5-91.7-23.1-45.4 9.9-98.4 60.9-98.4h79.7c35.8 0 64.8-29.6 64.9-65.3C511.5 97.1 368.1-26.9 204.3 5zM96 320c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm32-128c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128-64c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128 64c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z" class=""></path></svg>
                                Personalize as cores do sistema de acordo com a sua empresa, tornando a experiência mais <strong class="th-green-text">agradável e familiar</strong>.
                            </li>
                            <li>
                                <svg aria-hidden="true" focusable="false" data-icon="chart-pie" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 544 512"><path fill="currentColor" d="M527.79 288H290.5l158.03 158.03c6.04 6.04 15.98 6.53 22.19.68 38.7-36.46 65.32-85.61 73.13-140.86 1.34-9.46-6.51-17.85-16.06-17.85zm-15.83-64.8C503.72 103.74 408.26 8.28 288.8.04 279.68-.59 272 7.1 272 16.24V240h223.77c9.14 0 16.82-7.68 16.19-16.8zM224 288V50.71c0-9.55-8.39-17.4-17.84-16.06C86.99 51.49-4.1 155.6.14 280.37 4.5 408.51 114.83 513.59 243.03 511.98c50.4-.63 96.97-16.87 135.26-44.03 7.9-5.6 8.42-17.23 1.57-24.08L224 288z" class=""></path></svg>
                                Customize e crie gráficos interativos para visualizar dados de <strong class="th-green-text">vendas, financeiros e econômicos</strong>.
                            </li>
                        </ul>
                    </b-col>

                    <b-col cols="12" class="pt-5 text-center">
                        <b-button variant="dark" :to="{ name: 'Eleve' }">CONHEÇA O SISTEMA ELEVE</b-button>
                    </b-col>

                </b-row>
            </b-container>
        </div>

        <div class="hermes">
            <b-container>
                <b-row>
                    <b-col cols="12" md="6" order="2" order-md="1">
                        <h5>Sistema Hermes</h5>
                        <h2>Sistema de envio de e-mail marketing e newsletter.</h2>
                        <p>Sistema para envio de e-mails em massa, funciona como <strong class="th-green-text">módulo integrado ao Sistema Eleve ou de maneira individual</strong>.</p>
                        <ul>
                            <li>
                                <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M336 64h-80c0-35.3-28.7-64-64-64s-64 28.7-64 64H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM96 424c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm0-96c-13.3 0-24-10.7-24-24s10.7-24 24-24 24 10.7 24 24-10.7 24-24 24zm96-192c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm128 368c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16zm0-96c0 4.4-3.6 8-8 8H168c-4.4 0-8-3.6-8-8v-16c0-4.4 3.6-8 8-8h144c4.4 0 8 3.6 8 8v16z" class=""></path></svg>
                                Crie e gerencie <strong class="th-green-text">campanhas programáticas</strong> e defina listas de e-mails personalizados com poucos cliques.
                            </li>
                            <li>
                                <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M476 3.2L12.5 270.6c-18.1 10.4-15.8 35.6 2.2 43.2L121 358.4l287.3-253.2c5.5-4.9 13.3 2.6 8.6 8.3L176 407v80.5c0 23.6 28.5 32.9 42.5 15.8L282 426l124.6 52.2c14.2 6 30.4-2.9 33-18.2l72-432C515 7.8 493.3-6.8 476 3.2z" class=""></path></svg>
                                O envio dos e-mails é feito de forma automatizada, gerando <strong class="th-green-text">economia de tempo e eliminando erros humanos</strong>.
                            </li>
                        </ul>
                    </b-col>
                    <b-col class="notebook" cols="12" md="6" order="1" order-md="2">
                        <b-img class="mock" fluid :src="images.hermes"></b-img>
                    </b-col>

                </b-row>
            </b-container>
        </div>
        
        <b-container class="deps">
            <b-row>
                <b-col cols="12" class="title">
                    <h2>O que nossos clientes dizem</h2>
                </b-col>
                <b-col class="dep" cols="12" lg="4">
                    <div class="text">
                        Nós ficamos muito satisfeitos com o sistema Eleve, <strong class="th-green-text">ele atende e soluciona todas nossas necessidades</strong> e está sempre em evolução.
                    </div>
                    <div class="author">
                        <b-avatar size="3rem" class="mr-3 float-left"></b-avatar>
                        <div class="name">Patricia Ribeiro</div>
                        <div class="empresa">Dental Lina</div>
                    </div>
                </b-col>
                <b-col class="dep" cols="12" lg="4">
                    <div class="text">
                        Contratamos os serviços da Clouds para a nossa Promoção Fidelidade e o <strong class="th-green-text">resultado foi tão positivo que mudamos o sistema para o da Clouds</strong>.
                    </div>
                    <div class="author">
                        <b-avatar size="3rem" class="mr-3 float-left"></b-avatar>
                        <div class="name">Manoel Messias</div>
                        <div class="empresa">Estaciona Motos</div>
                    </div>
                </b-col>
                <b-col class="dep" cols="12" lg="4">
                    <div class="text">
                        Nosso controle de estoque e de caixa era uma bagunça, o <strong class="th-green-text">Eleve foi essencial para colocar a empresa em ordem</strong> e nos organizar melhor.
                    </div>
                    <div class="author">
                        <b-avatar size="3rem" class="mr-3 float-left"></b-avatar>
                        <div class="name">Rafael Honório</div>
                        <div class="empresa">Prisma Malhas</div>
                    </div>
                </b-col>
            </b-row>
        </b-container>
        
        <call-component text="Deixe sua empresa nas mãos de quem tem experiência e conte com as soluções mais completas do mercado." btnText="ENTRAR EM CONTATO" btnRef="Contato" />
    </div>
</template>

<script>
import Particles from './../components/Particles';
import CallComponent from './../components/CallComponent';

export default {
    name: 'Home',
    components: {
        CallComponent,
        Particles
    },
    data () {
        return {
            images: {
                eleve: require('./../assets/notebook-eleve.webp'),
                hermes: require('./../assets/notebook-hermes.webp')
            }
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'Clouds Sistemas';
            }
        },
    },
}
</script>
