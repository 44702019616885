import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Servicos from '../views/Servicos.vue'
import Eleve from '../views/Eleve.vue'
import Portfolio from '../views/Portfolio.vue'
import Sobre from '../views/Sobre.vue'
import Contato from '../views/Contato.vue'
import Os from '../views/Os.vue'
import OsRating from '../views/OsRating.vue'
import CloudsRating from '../views/CloudsRating.vue'
import PrivacidadeCookies from '../views/PrivacidadeCookies.vue'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home
    },
    // {
    //     path: '/servicos',
    //     name: 'Servicos',
    //     component: Servicos
    // },
    {
        path: '/sistema-eleve',
        name: 'Eleve',
        component: Eleve
    },
    {
        path: '/sobre',
        name: 'Sobre',
        component: Sobre
    },
    // {
    //     path: '/portfolio',
    //     name: 'Portfolio',
    //     component: Portfolio
    // },
    {
        path: '/contato',
        name: 'Contato',
        component: Contato
    },
    {
        path: '/os:idOS',
        redirect: '/os/:idOS'
    },
    {
        path: '/os/:idOS',
        name: 'Os',
        component: Os
    },
    {
        path: '/os/:idOS/rating/:rating',
        name: 'OsRating',
        component: OsRating
    },
    {
        path: '/privacidade-e-cookies',
        name: 'Privacidade',
        component: PrivacidadeCookies
    },

    {
        path: '/avaliacao',
        name: 'CloudsRating',
        component: CloudsRating
    },

]

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes,
    linkActiveClass: "active",
    linkExactActiveClass: "exact-active",
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    }
})

export default router
