<template>
    <div class="portfolio">
        <b-container class="page-header" fluid>
            <h2>Avalie</h2>
            <p>Como você avalia nossos serviços?</p>
        </b-container>

        <b-container class="py-5">

            <b-alert variant="warning" :show="error">Por favor, preencha todos os campos abaixo antes de enviar sua avaliação.</b-alert>

            <b-overlay :show="loading" rounded="sm">

                <b-row class="py-0">
                    <b-col cols="12" md="6">
                        <b-img class="avaliacao" :src="image" fluid></b-img>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-form @submit="onSubmit">
                            <div v-if="!hasEmail">
                                <p class="mb-2">Por favor, informe seu e-mail para que possamos validar sua avaliação:</p>
                                <b-form-input name="email" v-model="formData.email"  size="sm"></b-form-input>
                            </div>

                            <h5 :class="{ 'pt-3' : !hasEmail, 'pt-2' : hasEmail }">O quão satisfeito você está com os tópicos abaixo:</h5>

                            <p class="pt-3 mb-2" style="font-weight:bold">Suporte:</p>
                            <b-form-rating
                                size="sm"
                                icon-empty="cloud"
                                icon-full="cloud-fill"
                                :state="false"
                                variant="success" id="rating-10" v-model="formData.suporte" stars="10"></b-form-rating>

                            <p class="pt-3 mb-2" style="font-weight:bold">Agilidade no atendimento:</p>
                            <b-form-rating
                                size="sm"
                                icon-empty="cloud"
                                icon-full="cloud-fill"
                                variant="success" id="rating-10" v-model="formData.agilidade" stars="10"></b-form-rating>

                            <p class="pt-3 mb-2" style="font-weight:bold">Facilidade de utilização:</p>
                            <b-form-rating
                                size="sm"
                                icon-empty="cloud"
                                icon-full="cloud-fill"
                                variant="success" id="rating-10" v-model="formData.usabilidade" stars="10"></b-form-rating>

                            <p class="pt-3 mb-2" style="font-weight:bold">Documentação:</p>
                            <b-form-rating
                                size="sm"
                                icon-empty="cloud"
                                icon-full="cloud-fill"
                                variant="success" id="rating-10" v-model="formData.documentacao" stars="10"></b-form-rating>

                            <p class="pt-3 mb-2">Por favor, nos conte um pouco mais sobre sua experiência:</p>
                            <b-form-textarea
                                size="sm"
                                id="textarea"
                                v-model="formData.usrobservacao"
                                placeholder=""
                                rows="3"
                                max-rows="6"
                            ></b-form-textarea>

                            <div class="pt-4 mb-2">
                                <b-button type="submit" variant="outline-success">Enviar Avaliação</b-button>
                            </div>
                        </b-form>
                    </b-col>
                </b-row>

            
                <template #overlay v-if="rated">
                    <b-container class="my-5 py-4 thx">
                        <b-iconstack class="th-green-text" font-scale="6">
                            <b-icon stacked icon="circle"></b-icon>
                            <b-icon stacked icon="check"></b-icon>
                        </b-iconstack>
                        <div>
                            <div class="h4">Avaliação Enviada</div>
                            <div class="h5">Obrigado por reservar um tempo para avaliar nossos serviços, sua opinião é muito importante para nós!</div>
                        </div>
                    </b-container>
                </template>

            </b-overlay>
        </b-container>
        
    </div>
</template>

<style lang="scss" scoped>
.thx {
    max-width: 650px;
    display: flex;
    align-items: center;
    justify-content: center;

    .h4,
    .h5 {
        padding-left: 25px;
    }
}
</style>

<script>
export default {
    name: 'CloudsRating',
    components: {
    },
    data () {
        return {
            image: require('./../assets/avaliacao_alt.webp'),
            loading: false,
            rated: false,
            hasEmail: false,
            error: false,
            formData: {
                email: null,
                usrobservacao: null,
                suporte: null,
                usabilidade: null,
                agilidade: null,
                documentacao: null
            }
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'Clouds Sistemas - Ordem de Serviço';
            }
        },
    },
    mounted () {
        const urlParams = new URLSearchParams( window.location.search );

        console.log( urlParams.has('email') )

        if ( urlParams.has('email') )
        {
            this.hasEmail = true;
            this.formData.email = urlParams.get('email');
        }
        else
            this.hasEmail = false;
            
    },
    methods: {
        onSubmit(evt) {
            evt.preventDefault();
            this.error = false;

            if ( Object.values( this.formData ).indexOf( null ) > -1 || Object.values( this.formData ).indexOf( '' ) > -1 )
            {
                this.error = true;
                return;
            }

            this.loading = true;
            
            const params = new URLSearchParams();
            params.append('email',          this.formData.email );
            params.append('usrobservacao',  this.formData.usrobservacao );
            params.append('suporte',        this.formData.suporte );
            params.append('usabilidade',    this.formData.usabilidade );
            params.append('agilidade',      this.formData.agilidade );
            params.append('documentacao',   this.formData.documentacao );

            axios({
                method: 'post',
                url: 'https://api.surgeonline.com.br/helpdesk-ff/evaluations/',
                data: params
            }).then(response =>
            {  
                this.rated = true;
            })
            .catch(error => {
                this.loading = false;
            });
        },
    }
}
</script>

<style lang="scss" scoped>
div.form-control {
    height: auto;
    background-color: #e9ecef;
}
.avaliacao {
    border-radius: 9px;
}
</style>
