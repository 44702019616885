<template>
    <div class="eleve">
        <b-container class="page-header" fluid>
            <h2>Sistema Comercial Eleve</h2>
            <p>Sistema comercial da Clouds, pronto para emissão de notas fiscais e cupons ficais eletrônicos.</p>
        </b-container>

        <div class="sistema">
            <b-container>
                <b-row>
                    <b-col class="notebook" cols="12" md="6">
                        <b-img :src="images.notebook"></b-img>
                    </b-col>
                    <b-col cols="12" md="6">
                        <h5>Sistema Eleve</h5>
                        <h2>Sistema de gestão empresarial prático, rápido e seguro!</h2>
                        <p>Sistema pronto para <strong class="th-green-text">emissão de notas fiscais e cupons ficais eletrônicos</strong> para diversos segmentos comerciais.</p>
                        <ul>
                            <li>
                                <svg aria-hidden="true" focusable="false" data-icon="palette" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M204.3 5C104.9 24.4 24.8 104.3 5.2 203.4c-37 187 131.7 326.4 258.8 306.7 41.2-6.4 61.4-54.6 42.5-91.7-23.1-45.4 9.9-98.4 60.9-98.4h79.7c35.8 0 64.8-29.6 64.9-65.3C511.5 97.1 368.1-26.9 204.3 5zM96 320c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm32-128c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128-64c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32zm128 64c-17.7 0-32-14.3-32-32s14.3-32 32-32 32 14.3 32 32-14.3 32-32 32z" class=""></path></svg>
                                Personalize as cores do sistema <strong class="th-green-text">de acordo com a sua empresa, tornando a experiência mais agradável e familiar</strong>.
                            </li>
                            <li>
                                <svg aria-hidden="true" focusable="false" data-icon="chart-pie" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 544 512"><path fill="currentColor" d="M527.79 288H290.5l158.03 158.03c6.04 6.04 15.98 6.53 22.19.68 38.7-36.46 65.32-85.61 73.13-140.86 1.34-9.46-6.51-17.85-16.06-17.85zm-15.83-64.8C503.72 103.74 408.26 8.28 288.8.04 279.68-.59 272 7.1 272 16.24V240h223.77c9.14 0 16.82-7.68 16.19-16.8zM224 288V50.71c0-9.55-8.39-17.4-17.84-16.06C86.99 51.49-4.1 155.6.14 280.37 4.5 408.51 114.83 513.59 243.03 511.98c50.4-.63 96.97-16.87 135.26-44.03 7.9-5.6 8.42-17.23 1.57-24.08L224 288z" class=""></path></svg>
                                Customize e <strong class="th-green-text">crie gráficos interativos</strong> para visualizar dados de <strong class="th-green-text">vendas, financeiros e econômicos</strong>.
                            </li>
                        </ul>
                    </b-col>

                </b-row>
            </b-container>
        </div>

        <div class="boxes">
            <b-container>
                <b-row>
                    <b-col md="5" class="mx-auto">
                        <h3 class="title">O software de gestão comercial mais completo do mercado!</h3>
                    </b-col>
                </b-row>
                <b-row>
                    <b-col class="box" cols="12" md="4">
                        <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M232 248c0-8.8-7.2-16-16-16h-16c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16v-16zm-96 0c0-8.8-7.2-16-16-16h-16c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16v-16zm32 48h-16c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16zm96 0h-16c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16zm64-48c0-8.8-7.2-16-16-16h-16c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16v-16zm183.4 131.5l-25.5-178.3c-3.4-23.6-23.6-41.2-47.5-41.2H192V96h80c8.8 0 16-7.2 16-16V16c0-8.8-7.2-16-16-16H80c-8.8 0-16 7.2-16 16v64c0 8.8 7.2 16 16 16h80v64H73.6c-23.9 0-44.1 17.6-47.5 41.2L.6 379.5c-.4 3-.6 6-.6 9.1V464c0 26.5 21.5 48 48 48h416c26.5 0 48-21.5 48-48v-75.5c0-3-.2-6-.6-9zM96 64V32h160v32H96zM57.8 205.7c1.1-7.8 7.9-13.7 15.8-13.7h364.7c7.9 0 14.7 5.9 15.8 13.7L479.7 384H32.3l25.5-178.3zM480 464c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16v-48h448v48zm-72-232h-16c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16zm-48 64h-16c-8.8 0-16 7.2-16 16v16c0 8.8 7.2 16 16 16h16c8.8 0 16-7.2 16-16v-16c0-8.8-7.2-16-16-16z" class=""></path></svg>
                        <div>
                            <h5>Vendas</h5>
                            <p>Emissão de NF-e, NFC-e e CF-e (SAT) sempre em acordo com as constantes alterações das leis.</p>
                        </div>
                    </b-col>
                    <b-col class="box" cols="12" md="4">
                        <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path fill="currentColor" d="M117.65 277.65c6.25 6.25 16.38 6.25 22.63 0L192 225.94l84.69 84.69c6.25 6.25 16.38 6.25 22.63 0L409.54 200.4l29.49 29.5c15.12 15.12 40.97 4.41 40.97-16.97V112c0-8.84-7.16-16-16-16H363.07c-21.38 0-32.09 25.85-16.97 40.97l29.5 29.49-87.6 87.6-84.69-84.69c-6.25-6.25-16.38-6.25-22.63 0l-74.34 74.34c-6.25 6.25-6.25 16.38 0 22.63l11.31 11.31zM496 400H48V80c0-8.84-7.16-16-16-16H16C7.16 64 0 71.16 0 80v336c0 17.67 14.33 32 32 32h464c8.84 0 16-7.16 16-16v-16c0-8.84-7.16-16-16-16z" class=""></path></svg>
                        <div>
                            <h5>Financeiro</h5>
                            <p>Movimentações detalhadas por meio de relatórios, plano de contas e DRE.</p>
                        </div>
                    </b-col>
                    <b-col class="box" cols="12" md="4">
                        <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M624 224H480V16c0-8.8-7.2-16-16-16H176c-8.8 0-16 7.2-16 16v208H16c-8.8 0-16 7.2-16 16v256c0 8.8 7.2 16 16 16h608c8.8 0 16-7.2 16-16V240c0-8.8-7.2-16-16-16zm-176 32h64v62.3l-32-10.7-32 10.7V256zM352 32v62.3l-32-10.7-32 10.7V32h64zm-160 0h64v106.7l64-21.3 64 21.3V32h64v192H192V32zm0 224v62.3l-32-10.7-32 10.7V256h64zm-160 0h64v106.7l64-21.3 64 21.3V256h80v224H32V256zm576 224H336V256h80v106.7l64-21.3 64 21.3V256h64v224z" class=""></path></svg>
                        <div>
                            <h5>Controle de Estoque</h5>
                            <p>Compra, venda e estoque rigorosamente controlados dentro do sistema.</p>
                        </div>
                    </b-col>
                    <b-col class="box" cols="12" md="4">
                        <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512"><path fill="currentColor" d="M551.991 64H144.28l-8.726-44.608C133.35 8.128 123.478 0 112 0H12C5.373 0 0 5.373 0 12v24c0 6.627 5.373 12 12 12h80.24l69.594 355.701C150.796 415.201 144 430.802 144 448c0 35.346 28.654 64 64 64s64-28.654 64-64a63.681 63.681 0 0 0-8.583-32h145.167a63.681 63.681 0 0 0-8.583 32c0 35.346 28.654 64 64 64 35.346 0 64-28.654 64-64 0-18.136-7.556-34.496-19.676-46.142l1.035-4.757c3.254-14.96-8.142-29.101-23.452-29.101H203.76l-9.39-48h312.405c11.29 0 21.054-7.869 23.452-18.902l45.216-208C578.695 78.139 567.299 64 551.991 64zM208 472c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm256 0c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm23.438-200H184.98l-31.31-160h368.548l-34.78 160z" class=""></path></svg>
                        <div>
                            <h5>E-Commerce</h5>
                            <p>Integração com a Loja Virtual da Tray com atualização de status e emissão de NF-e automáticos.</p>
                        </div>
                    </b-col>
                    <b-col class="box" cols="12" md="4">
                        <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512"><path fill="currentColor" d="M280 240H168c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zm0 96H168c-4.4 0-8 3.6-8 8v16c0 4.4 3.6 8 8 8h112c4.4 0 8-3.6 8-8v-16c0-4.4-3.6-8-8-8zM112 232c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zm0 96c-13.3 0-24 10.7-24 24s10.7 24 24 24 24-10.7 24-24-10.7-24-24-24zM336 64h-88.6c.4-2.6.6-5.3.6-8 0-30.9-25.1-56-56-56s-56 25.1-56 56c0 2.7.2 5.4.6 8H48C21.5 64 0 85.5 0 112v352c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48V112c0-26.5-21.5-48-48-48zM192 32c13.3 0 24 10.7 24 24s-10.7 24-24 24-24-10.7-24-24 10.7-24 24-24zm160 432c0 8.8-7.2 16-16 16H48c-8.8 0-16-7.2-16-16V112c0-8.8 7.2-16 16-16h48v20c0 6.6 5.4 12 12 12h168c6.6 0 12-5.4 12-12V96h48c8.8 0 16 7.2 16 16v352z" class=""></path></svg>
                        <div>
                            <h5>Backup de Documentos Fiscais</h5>
                            <p>Armazenamento de documentos fiscais em nuvem de forma automática e segura.</p>
                        </div>
                    </b-col>
                    <b-col class="box" cols="12" md="4">
                        <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512"><path fill="currentColor" d="M632 240H336v-80h80c17.67 0 32-14.33 32-32V32c0-17.67-14.33-32-32-32H224c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h80v80H8c-4.42 0-8 3.58-8 8v16c0 4.42 3.58 8 8 8h120v80H64c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h160c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32h-64v-80h320v80h-64c-17.67 0-32 14.33-32 32v96c0 17.67 14.33 32 32 32h160c17.67 0 32-14.33 32-32v-96c0-17.67-14.33-32-32-32h-64v-80h120c4.42 0 8-3.58 8-8v-16c0-4.42-3.58-8-8-8zM224 384v96H64v-96h160zm0-256V32h192v96H224zm352 256v96H416v-96h160z" class=""></path></svg>
                        <div>
                            <h5>Funcionalidades</h5>
                            <p>Agenda, e-mail marketing, boletos, contas a pagar e receber e muito mais.</p>
                        </div>
                    </b-col>
                </b-row>
            </b-container>
        </div>

        <!-- <div class="sistema alt">
            <b-container>
                <b-row>
                    <b-col class="screen" cols="12" md="6">
                        <b-img :src="images.sisHome" fluid></b-img>
                    </b-col>
                    <b-col cols="12" md="6">
                        <h2>Gestão de Produtos e Estoque</h2>
                        <p>Importe seus produtos utilizando nossa planilha ou por meio das notas fiscais eletrônicas de entrada.</p>
                        <p>Crie etiquetas, gere relatórios e índices de vendas, customize dashboards para visualizar os custos de investimentos em produtos, mercadorias e matérias primas.</p>
                        <p>Sincronize seu estoque com seus canais de venda, mantendo-o atualizado em seu marketplace e loja virtual.</p>
                    </b-col>
                </b-row>
            </b-container>
        </div> -->

        <div class="sistema alt">
            <b-container>
                <b-row>
                    <b-col class="screen" cols="12" md="6">
                        <b-img :src="images.sisFinanceiro" fluid></b-img>
                    </b-col>
                    <b-col cols="12" md="6">
                        <h2>Sempre Conectado</h2>
                        <p>Com o módulo online é possível acompanhar os <strong class="th-green-text">resultados financeiros e econômicos </strong> da sua empresa, <strong class="th-green-text">em tempo real e de onde estiver</strong>.</p>
                        <p>Separe suas receitas e despesas por operações e centros de custos, <strong class="th-green-text">compare períodos e faça projeções</strong>.</p>
                    </b-col>
                </b-row>
            </b-container>
        </div>

        <div class="sistema alt right">
            <b-container>
                <b-row>
                    <b-col cols="12" md="6" order="2" order-md="1">
                        <h2>Relatórios Gerenciais</h2>
                        <p>Customize e crie <strong class="th-green-text">gráficos interativos</strong> para visualizar dados de vendas, financeiros e econômicos, ordenando-os <strong class="th-green-text">conforme sua preferência</strong>.</p>
                        <p><strong class="th-green-text">Interaja com o gráfico</strong> clicando sobre a fatia desejada, extraindo assim <strong class="th-green-text">informações detalhadas sobre cada uma delas</strong>.</p>
                        <p>Exporte os dados apresentados em formato compatível com <strong class="th-green-text">Excel (csv)</strong>.</p>
                    </b-col>
                    <b-col class="screen" cols="12" md="6" order="1" order-md="2">
                        <b-img :src="images.sisGerenciais" fluid></b-img>
                    </b-col>
                </b-row>
            </b-container>
        </div>

        <div class="sistema alt">
            <b-container>
                <b-row>
                    <b-col class="screen" cols="12" md="6">
                        <b-img :src="images.sisNFe" fluid></b-img>
                    </b-col>
                    <b-col cols="12" md="6" order="2" order-md="1">
                        <h2>Emissão de NF-e, NFC-e e CF-e</h2>
                        <p>O sistema Eleve está pronto para <strong class="th-green-text">emitir Nota Fiscal Eletrônica, Cupom Fiscal Eletrônico e Nota Fiscal de Consumidor Eletrônica</strong> de maneira rápida e intuitiva, sempre em acordo com a legislação atual.</p>
                        <p><strong class="th-green-text">Padronize o cálculo de imposto</strong> por NCM e operação, a fim de evitar inconsistências na forma de tributação.</p>
                        <p>Aplique descontos utilizando <strong class="th-green-text">tabelas de preço predefinidas</strong>.</p>
                    </b-col>
                </b-row>
            </b-container>
        </div>
        
        <call-component text="Deixe sua empresa nas mãos de quem tem experiência e conte com as soluções mais completas do mercado." btnText="ENTRAR EM CONTATO" btnRef="Contato" />

    </div>
</template>

<script>
import CallComponent from './../components/CallComponent';
import Particles from './../components/Particles';

export default {
    name: 'SistemaEleve',
    components: {
        CallComponent,
        Particles
    },
    data () {
        return {
            images: {
                notebook: require('./../assets/notebook-eleve.webp'),
                sisHome: require('./../assets/sistema-eleve/home.webp'),
                sisGerenciais: require('./../assets/sistema-eleve/relatorios-gerenciais.webp'),
                sisFinanceiro: require('./../assets/sistema-eleve/dashboard-financeiro.webp'),
                sisNFe: require('./../assets/sistema-eleve/nfe.webp')
            }
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'Clouds Sistemas - Sistema Comercial Eleve';
            }
        },
    },
}
</script>