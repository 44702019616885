<template>
    <div class="servicos">
        <b-container class="page-header" fluid>
            <h2>Serviços</h2>
            <p>Nossos principais serviços. Procurando por algo que não está na lista? Entre em contato.</p>
        </b-container>

        <b-container>
            <div class="row pt-5 pb-4 text-center">
                <b-col class="pb-4" cols="12" md="4" v-bind="servico" v-for="servico in servicos" :key="servico.id">
                    <div class="s-wrap">
                        <div class="icon-wrap pb-3">
                            <b-icon :icon="servico.icon" style="width: 55px; height: 55px;"></b-icon>
                        </div>
                        <h4>{{ servico.title }}</h4>
                        <p>{{ servico.shortDesc }}</p>
                    </div>
                </b-col>
            </div>
        </b-container>

    </div>
</template>

<script>
export default {
    name: 'Contato',
    components: {
    },
    data () {
        return {
            servicos: [
                {"icon":"window","title":"Sistema Eleve","shortDesc":"Nulla anim aliqua nisi proident elit veniam deserunt incididunt excepteur in do Lorem qui dolore."},
                {"icon":"window","title":"Sistema Eleve","shortDesc":"Sit ullamco elit sunt id ut irure id duis dolor. Ipsum duis nisi do fugiat eu ipsum ut aute dolore."},
                {"icon":"window","title":"Sistema Eleve","shortDesc":"Deserunt Lorem irure ea enim et in veniam. Nisi id excepteur adipisicing nulla dolore nostrud."},
                {"icon":"window","title":"Sistema Eleve","shortDesc":"Lorem ullamco tempor laborum reprehenderit et nulla consequat consectetur enim."},
                {"icon":"window","title":"Sistema Eleve","shortDesc":"Qui occaecat dolor officia ut excepteur aliquip est nostrud."},
                {"icon":"window","title":"Sistema Eleve","shortDesc":"Velit incididunt ut elit culpa laboris laboris laborum reprehenderit commodo et ipsum incididunt."},
                {"icon":"window","title":"Sistema Eleve","shortDesc":"Deserunt enim irure consequat adipisicing consequat ex enim dolore duis excepteur sit."},
                {"icon":"window","title":"Sistema Eleve","shortDesc":"Sit quis laborum ipsum ut. Anim eiusmod in sint ad ipsum ipsum dolor in occaecat."},
                {"icon":"window","title":"Sistema Eleve","shortDesc":"Lorem excepteur reprehenderit anim voluptate laborum aliquip amet laboris anim sit voluptate"},
            ],
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'Clouds Sistemas - Serviços';
            }
        },
    },
}
</script>
