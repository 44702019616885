<template>
    <div class="sobre">
        <b-container class="page-header" fluid>
            <h2>Sobre Nós</h2>
            <p>Somos uma empresa especializada no desenvolvimento de <strong>softwares e aplicativos para gestão comercial</strong>.</p>
        </b-container>

        <b-container class="py-5 txt">
            <b-row class="pb-3">
                <b-col cols="12">
                    <h4>Clouds Sistemas</h4>
                    <p class="pb-3">
                        Desenvolvemos e distribuimos sistemas e aplicativos para resolver e descomplicar a rotina de processos e empresas, além de atender às mais diversas necessidades do mercado. Atuamos principalmente na área de automação comercial com o software Eleve. Esse software atende ao mercado em geral, além de auxiliar a gestão de empresas e estar de acordo às exigências das leis vigentes sobre emissão de notas fiscais e cupons eletrônicos. Saiba mais sobre o Eleve
                    </p>
                </b-col>
            </b-row>
            <!--<b-row>
                <b-col cols="12" md="4">
                    <h4>Missão</h4>
                    <p>Minim cupidatat Lorem quis do dolore pariatur. Ea est nisi occaecat magna do. Esse nulla commodo mollit fugiat sit aliquip nulla veniam labore sint.</p>
                </b-col>
                <b-col cols="12" md="4">
                    <h4>Visão</h4>
                    <p>Et tempor laboris pariatur enim nisi exercitation in. Duis aute cupidatat pariatur cillum deserunt qui sunt voluptate duis mollit.</p>
                </b-col>
                <b-col cols="12" md="4">
                    <h4>Valores</h4>
                    <ul>
                        <li>Lorem Ipsum</li>
                        <li>Ea est nisi occaecat</li>
                        <li>Duis cupidatat</li>
                        <li>Minim cupidatat</li>
                    </ul>
                </b-col>
            </b-row>-->
        </b-container>
        
        <call-component text="Deixe sua empresa nas mãos de quem tem experiência e conte com as soluções mais completas do mercado." btnText="ENTRAR EM CONTATO" btnRef="Contato" />

    </div>
</template>

<script>
import CallComponent from './../components/CallComponent';

export default {
    name: 'Contato',
    components: {
        CallComponent,
    },
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'Clouds Sistemas - Sobre';
            }
        },
    },
}
</script>
