<template>
    <div id="app">
        <header-component />
        <router-view />
        <footer-component />
        
        <div class="cookies" v-if="!cookies">
            Ao navegar neste site, você aceita os cookies que usamos para melhorar sua experiência. <b-link :to="{ name: 'Privacidade' }">Mais informações</b-link>
            <b-button variant="secondary" size="sm" @click="acceptCookies()">Entendi</b-button>
        </div>
    </div>
</template>

<script>
import HeaderComponent from '@/components/HeaderComponent.vue'
import FooterComponent from '@/components/FooterComponent.vue'

export default {
    components: {
        HeaderComponent,
        FooterComponent,
    },
    data () {
        return {
            cookies: false
        }
    },
    created () {
        if ( this.checkAcceptedCookie('ac') == 1 )
            this.cookies = true;
    },
    methods: {
        acceptCookies: function () {
            var d = new Date();
            d.setTime( d.getTime() + ( 7 * 24 * 60 * 60 * 1000 ) );
            var expires = "expires="+ d.toUTCString();
            document.cookie = "ac=1;" + expires + ";path=/";

            this.cookies = true;
        },
        checkAcceptedCookie: function ( cname ) {
            var name = cname + "=";
            var decodedCookie = decodeURIComponent( document.cookie );
            var ca = decodedCookie.split(';');
            for(var i = 0; i <ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') {
                    c = c.substring(1);
                }
                if (c.indexOf(name) == 0) {
                    return c.substring(name.length, c.length);
                }
            }
            return "";
        }
    }
}
</script>

<style lang="scss" scoped>
.cookies {
    background:rgba($color: #333333, $alpha: .9);
    padding:15px 5%;
    color:#ffffff;
    text-align: center;
    font-size: .85rem;
    bottom:1rem;
    left: 1rem;
    right: 1rem;
    border-radius: 10px;
    position:fixed;
    z-index:6000;

    a {
        color:#ffffff;
        text-decoration: underline;
        margin-right: 1.5rem;
    }

    @media (max-width: 991.98px) {
        button {
            width:100%;
            margin-top:15px;
        }
    }
}
</style>