<template>
    <div class="os">
        <b-container class="page-header" fluid>
            <h2>Ordem de Serviço {{ (os.id)? '#' + os.id : '' }}</h2>
            <p>Acompanhe em tempo real como está a sua solicitação.</p>
        </b-container>

        <b-container style="padding-top:3rem;min-height:610px;" v-if="!isOS">
            <h3 class="text-center">Ordem de serviço pela qual você procura não existe.</h3>
            <p class="text-center py-2">Confira se o número da Ordem de Serviço está correto. Se você acredita que isso é um erro, por favor, <b-link :to="{ name: 'Contato' }">entre em contato</b-link>.</p>
        </b-container>

        <b-container class="py-5" v-if="isOS">
            <b-overlay :show="loading" variant="white" opacity="1" rounded="lg">
                <b-row class="py-0">
                    <b-col cols="12" md="3">
                        <b-form-group label="Data Abertura:" label-for="dataAbertura">
                            <b-form-input readonly id="dataAbertura" v-model="os.dataAbertura" trim></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                        <b-form-group label="Hora Abertura:" label-for="horaAbertura">
                            <b-form-input readonly id="horaAbertura" v-model="os.horaAbertura" trim></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                        <b-form-group label="Data Agendamento:" label-for="dataAgendamento">
                            <b-form-input readonly id="dataAgendamento" v-model="os.dataAgendamento" trim></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="3">
                        <b-form-group label="Hora Agendamento:" label-for="horaAgendamento">
                            <b-form-input readonly id="horaAgendamento" v-model="os.horaAgendamento" trim></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row class="py-0">
                    <b-col cols="12" md="6">
                        <b-form-group label="Empresa:" label-for="empresa">
                            <b-form-input readonly id="empresa" v-model="os.empresa" trim></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-form-group label="Colaborador Responsável:" label-for="colaborador">
                            <b-form-input readonly id="colaborador" v-model="os.colaborador" trim></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row class="py-0">
                    <b-col cols="12" md="4">
                        <b-form-group label="Data Conclusão:" label-for="dataConclusao">
                            <b-form-input readonly id="dataConclusao" v-model="os.dataConclusao" trim></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                        <b-form-group label="Hora Conclusão:" label-for="horaConclusao">
                            <b-form-input readonly id="horaConclusao" v-model="os.horaConclusao" trim></b-form-input>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="4">
                        <b-form-group label="Status:" label-for="responsavel">
                            <b-form-input readonly id="status" :state="!os.status" v-model="os.statusTxt" trim></b-form-input>
                        </b-form-group>
                    </b-col>
                </b-row>
                <b-row class="py-0">
                    <b-col cols="12">
                        <p>Life Time:</p>
                        <b-progress :value="os.lifeTime" :max="100" class="mb-4" animated></b-progress>
                    </b-col>
                </b-row>
                <b-row class="py-0">
                    <b-col cols="12">
                        <p>Descrição:</p>
                        <div class="form-control mb-5" v-html="os.descricao">
                        </div>
                    </b-col>
                </b-row>
                <b-row class="py-0">
                    <b-col cols="12">
                        <b-table striped hover :items="items" :fields="fields">
                            <template #cell(descricao)="data">
                                <span v-html="data.value"></span>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>
            </b-overlay>
        </b-container>
        
    </div>
</template>

<script>
// http://localhost:8080/os/7ce04753-9bcf-11eb-8ce5-525400bc9a73/
export default {
    name: 'Os',
    components: {
    },
    data () {
        return {
            loading: true,
            isOS: true,
            os: {
                id              : null,
                dataAbertura    : null,
                horaAbertura    : null,
                dataAgendamento : null,
                horaAgendamento : null,
                dataConclusao   : null,
                horaConclusao   : null,
                empresa         : null,
                colaborador     : null,
                status          : null,
                statusTxt       : null,
                concluido       : false,
                lifeTime        : null,
                descricao       : null
            },
            fields: [
                { key: 'nome', label: 'Colaborador' },
                { key: 'datamov', label: 'Data' },
                { key: 'descricao', label: 'Descrição' }
            ],
            items: [
                { nome:'Clouds', datamov: '-', descricao: 'No momento não temos movimentações para exibir, por favor, retorne em breve para mais informações.' }
            ]
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'Clouds Sistemas - Ordem de Serviço';
            }
        },
    },
    created () {
        axios.get( 'https://api.surgeonline.com.br/helpdesk-ff/os/' + this.$route.params.idOS + '/' )
        .then(response => {
            this.os.id              = response.data.id;
            this.os.dataAbertura    = ( response.data.dataabertura != null )? this.convertDate( response.data.dataabertura ) : '-';
            this.os.horaAbertura    = response.data.horaabertura;
            this.os.dataAgendamento = ( response.data.dataagendamento != null )? this.convertDate( response.data.dataagendamento ) : '-';
            this.os.horaAgendamento = response.data.horaagendamento;
            this.os.dataConclusao   = ( response.data.dataconclusao != null )? this.convertDate( response.data.dataconclusao ) : '-';
            this.os.horaConclusao   = ( response.data.horaconclusao != null )? response.data.horaconclusao : '-';
            this.os.empresa         = response.data.empresa.razao;
            this.os.colaborador     = ( response.data.Colaborador != null )? response.data.Colaborador.usuario.nome : '-';
            this.os.status          = response.data.estado;
            this.os.statusTxt       = ( response.data.estado == true )? 'Pendente' : 'Concluída';
            this.os.lifeTime        = response.data.lifetime.percentual;
            this.os.descricao       = response.data.descricao;

            this.getMovimentacaoOS();
        })
        .catch(error => {
            this.isOS = false;
        }).finally(() => {
            this.loading = false
        });
    },
    methods: {
        convertDate( d )
        {
            function pad(s) { return (s < 10) ? '0' + s : s; }

            d = d.split('-');
            var date = new Date( d[0], d[1], d[2] );

            var day = pad( date.getDate() );
            var month = pad( date.getMonth() );
            var year = date.getFullYear();

            return day + "/" + month + "/" + year;
        },
        getMovimentacaoOS ( os )
        {
            axios.get( 'https://cloudssistemas.com.br/osdata/?os=' + this.os.id )
            .then(response => {
                if ( response.data.length > 0 )
                    this.items  = response.data;
            });
        }
    }
}
</script>
