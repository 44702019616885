<template>
    <div class="privacidade">
        <b-container class="page-header" fluid>
            <h2>Privacidade e Cookies</h2>
            <p>Esse termo tem como objetivo deixar claro a forma como os seus dados vão ser utilizados.</p>
        </b-container>
        <b-container class="py-5 text">
            
            <b-row>
                <b-col>
                    <p>Esta Política de Privacidade e Cookies visa informá-lo, enquanto visitante do nosso site, que utilizamos cookies com o objetivo de lhe oferecer uma experiência de navegação otimizada, fluida e adaptada ao seu uso. A sua privacidade é muito importante para nós. Como tal, elaboramos esta política para que possa saber como usamos os cookies e como pode gerenciá-los.</p>

                    <h3>O que são cookies e para que servem?</h3>
                    <p>Cookies são ferramentas essenciais na navegação online. Consistem em arquivos que identificam a interação entre você, nosso site e nossas redes sociais ou anúncios, e que são transferidos para o seu equipamento (computador, tablete ou smartphone) visando otimizar e personalizar a sua experiência de navegação nos sites. O termo “cookie” pode ser usado para descrever uma série de tecnologias, incluindo, mas não se limitando a pixel tags, web beacons e identificadores de dispositivo móvel.</p>

                    <h3>Que tipos de cookies utilizamos?</h3>
                    <p>Cookies de sessão: são cookies temporários, que não ficam registrados no disco rígido do usuário. As informações obtidas por estes cookies servem para analisar padrões de tráfego do site e identificar problemas, que nos permitem melhorar o conteúdo do site e a navegação.</p>
                    <p>Cookies analíticos: são aqueles que nos permitem realizar a análise estatística de como os usuários usam o site, monitorizar o desempenho e conhecer quais as páginas mais populares, determinar a razão de algumas páginas estarem apresentando mensagens de erro. Estes cookies são utilizadas apenas para efeitos de criação e análise estatística, sem recolher informações de carácter pessoal.</p>
                    <p>Cookies de funcionalidade: os cookies de funcionalidade permitem relembrar as preferências do utilizador relativamente à navegação nos Websites, não necessitando, assim, de o reconfigurar e personalizar cada vez que o visita.</p>

                    <h3>Como coletamos seus dados pessoais?</h3>
                    <p>Os seguintes tipos de informações pessoais podem ser coletados, armazenados e utilizados:</p>
                    <ul>
                        <li>Informações sobre o computador, incluindo endereço IP, dados geográficos locais, tipo e versão do navegador e sistema operacional.</li>
                        <li>Informações sobre a sua visita e uso deste site, incluindo fontes de referência, duração da visita, visualizações de páginas e caminhos de navegação no site.</li>
                        <li>Informações que você insere ao se registrar em nosso site ou executar uma solicitação de serviço, como o seu e-mail, nome.</li>
                    </ul>
                    <p>As informações que você insere ao enviar informações em nosso site. Por exemplo, seu nome, e-mail, telefone, abertura de os (Ordem de Serviço) etc. As informações que você insere para configurar um login e senha no que tange o acesso ao Website. Informações geradas quando você usa nosso site, incluindo quando, com que frequência o usa e em que circunstâncias. Informações sobre os documentos enviados por você, através de mensagens via Fale Conosco. Qualquer outra informação pessoal que você nos enviar.</p>

                    <h3>Uso de informações pessoais</h3>
                    <p>As informações pessoais enviadas para nós através do nosso site serão utilizadas para os fins especificados nesta política ou nas páginas relacionadas do site. Podemos usar suas informações pessoais para os seguintes fins:</p>
                    <ul>
                        <li>Registro e uso para cadastro de atendimento.</li>
                        <li>Permitir a utilização dos serviços online disponibilizados no nosso WebSite.</li>
                        <li>Se você solicitar ou se necessário, enviar-lhe informações sobre as ordens de serviço.</li>
                        <li>Enviar notificações por e-mail e SMS para fins de comunicação de ocorrências, de Pedidos de serviços.</li>
                        <li>OBS: (podendo cancelar o recebimento das informações a qualquer momento, através de links disponibilizados em nossa plataforma, ou até mesmo solicitar diretamente pelos nossos meios de contato).</li>
                    </ul>

                    <h3>Mantenha nosso Website seguro e evite fraudes.</h3>
                    <p>Verifique o cumprimento dos termos e condições que regem o uso do nosso WebSite.</p>
                    <p>Portanto, ao visitar as páginas do WebSite sem desabilitar tais tecnologias ("identificadores anônimos", "cookies", etc.), você escolhe explicitamente este tipo de navegação e autoriza o armazenamento de informações através dessas tecnologias para os fins acima mencionados.</p>
                    <p>Exceto conforme exigido por lei, não forneceremos suas informações pessoais a terceiros.</p>

                    <h3>Divulgação de informações pessoais serão feitas para:</h3>
                    <ul>
                        <li>Cumprir nossas obrigações legais.</li>
                        <li>A qualquer um de nossos colaboradores, consultores profissionais, fornecedores ou subcontratados.</li>
                    </ul>
                    
                    <h3>As informações que podem ser divulgadas são com o intuito de:</h3>
                    <ul>
                        <li>Cumprimento da lei</li>
                        <li>Para estabelecer, exercer ou defender nossos direitos legais (incluindo o fornecimento de informações a terceiros para fins de prevenção e redução de fraudes)</li>
                    </ul>
                    
                    <h3>Como desativar os Cookies?</h3>
                    <p>A maior parte dos navegadores (browsers) estão configurados de modo a aceitarem cookies. Caso pretenda, poderá alterar a configuração no seu browser. Caso opte por desativar os cookies que utilizamos isso poderá ter impacto na navegação, poderá experimentar uma navegação limitada e não receber informação personalizada durante a utilização.</p>
                    <p>Você pode seguir um desses links para desabilitar o uso de cookies:</p>
                    <ul>
                        <li><a href="https://support.google.com/accounts/answer/61416?co=GENIE.Platform%3DAndroid&hl=pt-BR" target="_blank">Google Chrome</a></li>
                        <li><a href="https://support.mozilla.org/pt-BR/kb/desative-cookies-terceiros-impedir-rastreamento" target="_blank">Mozilla Firefox</a></li>
                        <li><a href="https://support.microsoft.com/pt-br/topic/excluir-e-gerenciar-cookies-168dab11-0753-043d-7c16-ede5947fc64d" target="_blank">Microsoft Edge</a></li>
                    </ul>

                    <h3>Bibliografia:</h3>
                    <ul>
                        <li>Legislação.</li>
                        <li>Lei Geral de Proteção de Dados Pessoais (LGPD) (Lei nº 13.709/2018).</li>
                        <li>Marco Civil da Internet (Lei nº 12.965/2014) e sua Regulamentação (Decreto nº 8.771/2016).</li>
                        <li>Código Civil (Lei nº 10.406/2002)</li>
                    </ul>

                </b-col>
            </b-row>

        </b-container>
    </div>
</template>

<style lang="scss" scoped>
.text {
    h3 {
        margin-bottom:.5rem;
    }
    ul li,
    p {
        text-align: justify;
        line-height: 1.75rem;
    }
    a {
        color:#02a363
    }
}
</style>

<script>
export default {
    name: 'PrivacidadeCookies',
    components: {
    },
    data () {
        return {
        }
    },
}
</script>
