<template>
    <div class="portfolio">
        <b-container class="page-header" fluid>
            <h2>Portfolio</h2>
            <p>Conheça alguns dos projetos desenvolvidos pela Clouds Sistemas.</p>
        </b-container>

        <b-container class="py-5 projetos">
            <div v-bind:class="index % 2 !== 0 ? 'left' : 'right'" class="row" v-for="(projeto, index) in projetos" :key="projeto.id">
                <b-col>
                    <b-img-lazy v-bind="imgProps" :src="projeto.image" fluid :alt="projeto.title"></b-img-lazy>
                    <div class="py-md-3">
                        <h4 class="pt-2">{{ projeto.title }}</h4>
                        <p>{{ projeto.desc }}</p>
                    </div>
                </b-col>
            </div>
        </b-container>
        
        <call-component text="Deixe sua empresa nas mãos de quem tem experiência e conte com as soluções mais completas do mercado." btnText="ENTRAR EM CONTATO" btnRef="Contato" />

    </div>
</template>

<script>
import CallComponent from './../components/CallComponent';

export default {
    name: 'Contato',
    components: {
        CallComponent,
    },
    data () {
        return {
            imgProps: {
                center: true,
                fluidGrow: true,
                blank: true,
                blankColor: '#bbb',
                width: 690,
                height: 430
            },
            projetos: [
                {"id":1,"image":"http://picsum.photos/690/430?random=1","title":"Eu velit ea exercitation.","desc":"Reprehenderit exercitation qui id magna occaecat ullamco reprehenderit nostrud nisi. Eu reprehenderit cillum sint cillum elit nostrud commodo fugiat quis occaecat proident sint culpa. Laboris aliquip dolor aliquip Lorem amet.."},
                {"id":2,"image":"http://picsum.photos/690/430?random=2","title":"Aliquip consectetur irure.","desc":"Labore duis consectetur aute commodo voluptate non incididunt veniam do ad quis exercitation duis dolore. Culpa consectetur mollit excepteur culpa minim dolore officia Lorem nulla. Sunt do est eiusmod veniam incididunt magna non pariatur ut do deserunt minim."}
            ]
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'Clouds Sistemas - Portfolio';
            }
        },
    },
}
</script>
