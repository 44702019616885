<template>
    <div class="contato">
        <b-container class="page-header" fluid>
            <h2>Contato</h2>
            <p>Não hesite em nos contatar, estamos ansiosos para atendê-lo.</p>
        </b-container>
        <b-container class="py-5 text">
            <b-row>
                <b-col cols="12" md="5">
                    <ul class="py-0 py-md-5">
                        <li>
                            <b-icon icon="telephone"></b-icon>
                            <h6>Telefone e WhatsApp:</h6>
                            <a class="text-default" href="https://wa.me/5512997448778" target="_blank">(12) 99744-8778</a>
                        </li>
                        <li>
                            <b-icon icon="envelope"></b-icon>
                            <h6>E-mail:</h6>
                            <a class="text-default" href="mailto:contato@cloudssistemas.com.br">contato@cloudssistemas.com.br</a>
                        </li>
                        <li>
                            <b-icon icon="geo-alt"></b-icon>
                            <h6>Endereço:</h6>
                            <p>Av. Cassiano Ricardo, 601, Salas 61/63<br /> 6º andar - Ed. The One<br /> Jardim Aquarius. São José dos Campos - SP</p>
                        </li>
                    </ul>
                </b-col>

                <b-col>
                    <b-overlay :show="formOverlay" rounded="sm">
                        <b-form @submit="onSubmit">
                            <b-form-group label="Nome:" label-for="nome">
                                <b-form-input id="nome" v-model="form.nome" type="text" required placeholder="Nome Completo"></b-form-input>
                            </b-form-group>

                            <div class="row">
                                <div class="col-md-6">
                                    <b-form-group label="E-mail:" label-for="email">
                                        <b-form-input id="email" v-model="form.email" type="email" required placeholder="email@site.com.br"></b-form-input>
                                    </b-form-group>
                                </div>
                                <div class="col-md-6">
                                    <b-form-group label="Telefone:" label-for="telefone">
                                        <b-form-input id="telefone" v-model="form.telefone" type="text" required placeholder="(00) 00000-0000"></b-form-input>
                                    </b-form-group>
                                </div>
                            </div>

                            <b-form-group label="Mensagem" label-for="mensagem">
                                <b-form-textarea id="mensagem" v-model="form.msg" placeholder="Deixe uma mensagem..." rows="4" max-rows="6"></b-form-textarea>
                            </b-form-group>

                            <b-button type="submit" variant="outline-dark">Enviar</b-button>
                        </b-form>
                        <template v-slot:overlay>
                            <div class="text-center">
                                <b-spinner label="Enviando..." variant="primary"></b-spinner>
                                <p class="pt-3">Enviando Formulário...</p>
                            </div>
                        </template>
                    </b-overlay>
                </b-col>

            </b-row>
        </b-container>
        <b-container fluid class="responsive-map">
            <iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d14666.535461681133!2d-45.9106911!3d-23.2200102!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x58233eb8f2fdedfc!2sClouds%20Sistemas!5e0!3m2!1spt-BR!2sbr!4v1620240351879!5m2!1spt-BR!2sbr" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe>
        </b-container>
    </div>
</template>

<script>
export default {
    name: 'Contato',
    components: {
    },
    data () {
        return {
            formOverlay: false,
            form: {
                nome        : '',
                email       : '',
                telefone    : '',
                assunto     : '',
                msg         : '',
            }
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'Clouds Sistemas - Contato';
            }
        },
    },
    methods: {
        onSubmit(evt) {
            evt.preventDefault();

            this.formOverlay = true;
            
            const params = new URLSearchParams();
            params.append('nome',       this.form.nome);
            params.append('email',      this.form.email);
            params.append('telefone',   this.form.telefone);
            params.append('msg',        this.form.msg);


            axios({
                method: 'post',
                url: '/mail/',
                data: params
            }).then(response =>
            {
                console.log( response.data );

                var variant = 'danger';
                this.formOverlay = false;

                if ( response.data.status == 1 ) {
                    var variant = 'success';
                    this.resetForm();
                }

                this.$bvToast.toast(
                    response.data.msg,
                    {
                        title: 'Contato',
                        variant: variant,
                        solid: true
                    }
                );
            }).catch(error => {
                this.formOverlay = false;
                this.$bvToast.toast(
                    'E-mail não enviado! Por favor, confira o formulário e tente novamente.',
                    {
                        title: 'Contato',
                        variant: 'danger',
                        solid: true
                    }
                );
            });
        },
        resetForm () {
            this.form.nome          = null;
            this.form.email         = null;
            this.form.telefone      = null;
            this.form.msg           = null;
        }
    },
}
</script>
