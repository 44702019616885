<template>
    <div class="os">
        <b-container class="page-header" fluid>
            <h2>Avaliação de Ordem de Serviço</h2>
            <p>Como você avalia o serviço prestado para essa Ordem de Serviço?</p>
        </b-container>

        <b-overlay :show="loading" rounded="sm" style="min-height:544px;">

            <b-container style="padding-top:3rem;min-height:570px;" v-if="os.id === false">
                <h3 class="text-center">Ordem de serviço pela qual você procura não existe.</h3>
                <p class="text-center py-2">Confira se o número da Ordem de Serviço está correto. Se você acredita que isso é um erro, por favor, <b-link :to="{ name: 'Contato' }">entre em contato</b-link>.</p>
            </b-container>

            <b-container class="my-5 py-4" v-if="os.id">
                <b-form @submit="onSubmit">
                    <b-row class="rating">
                        <b-col cols="12" md="5" class="left p-3 text-center">
                            <p class="h3 m-0">#{{ os.id }} {{ os.title }}</p>
                            <p class="h1 my-4">{{ rating }}</p>
                            <b-form-radio-group
                                id="btn-radios-2"
                                v-model="rating"
                                :options="options"
                                button-variant="outline-light"
                                name="rating"
                                buttons
                            ></b-form-radio-group>
                        </b-col>
                        <b-col cols="12" md="7" class="right p-3">
                            <p>Obrigado por reservar um tempo para avaliar nosso serviço, sua opinião é muito importante para nós! Por favor, conte-nos um pouco sobre o que motivou você a avaliar o serviço prestado com essa nota:</p>
                            <b-form-textarea
                                id="textarea"
                                v-model="obs"
                                rows="10"
                                max-rows="10"
                                :state="validation"
                            ></b-form-textarea>
                            <b-form-invalid-feedback :state="validation">
                                Por favor, conte-nos um pouco sobre sua experiência.
                            </b-form-invalid-feedback>

                            <div class="pt-4">
                                <b-button type="submit" variant="outline-success">Enviar Avaliação</b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-form>
            </b-container>
            
            <template #overlay v-if="rated || active">
                <b-container class="my-5 py-4 thx" v-if="active">
                    <b-iconstack class="th-green-text" font-scale="6">
                        <b-icon stacked icon="circle"></b-icon>
                        <b-icon stacked icon="exclamation"></b-icon>
                    </b-iconstack>
                    <div>
                        <div class="h4">Ordem de Serviço em Andamento</div>
                        <div class="h5">Obrigado por reservar um tempo para avaliar nosso serviço, mas ainda estamos trabalhando na sua Ordem de Serviço.</div>
                    </div>
                </b-container>

                <b-container class="my-5 py-4 thx" v-if="!active">
                    <b-iconstack class="th-green-text" font-scale="6">
                        <b-icon stacked icon="circle"></b-icon>
                        <b-icon stacked icon="check"></b-icon>
                    </b-iconstack>
                    <div>
                        <div class="h4">Ordem de Serviço Avaliada!</div>
                        <div class="h5">Obrigado por reservar um tempo para avaliar nossos serviços, sua opinião é muito importante para nós!</div>
                    </div>
                </b-container>
            </template>

        </b-overlay>
        
    </div>
</template>

<style lang="scss" scoped>
.form-control.is-invalid {
    border-color:#ced4da !important;
    background-image: none;

    &:focus {
        box-shadow:none
    }
}
.invalid-feedback {
    color:#252525
}
.thx {
    max-width: 650px;
    display: flex;
    align-items: center;
    justify-content: center;

    .h4,
    .h5 {
        padding-left: 25px;
    }
}
</style>

<script>
// http://localhost:8080/os/7ce04753-9bcf-11eb-8ce5-525400bc9a73/rating/7
export default {
    name: 'OsRating',
    components: {
    },
    data () {
        return {
            loading: true,
            rated: false,
            active: false,
            os: {
                id:     null,
                uuid:   this.$route.params.idOS,
                title:  null
            },
            rating: ( this.$route.params.rating > 0 && this.$route.params.rating < 11 )? this.$route.params.rating : '?',
            obs: '',
            options: [
                { text: '1', value: 1 },
                { text: '2', value: 2 },
                { text: '3', value: 3 },
                { text: '4', value: 4 },
                { text: '5', value: 5 },
                { text: '6', value: 6 },
                { text: '7', value: 7 },
                { text: '8', value: 8 },
                { text: '9', value: 9 },
                { text: '10', value: 10 }
            ]
        }
    },
    watch: {
        $route: {
            immediate: true,
            handler(to, from) {
                document.title = to.meta.title || 'Clouds Sistemas - Avaliação de Ordem de Serviço';
            }
        },
    },
    created () {
        axios.get( 'https://api.surgeonline.com.br/helpdesk-ff/os/' + this.os.uuid + '/' )
        .then(response => {
            this.os.id      = response.data.id;
            this.os.title   = response.data.predescricao;
            this.active     = response.data.estado;

            if ( response.data.usrfeedback !== 0 ) this.showRatedMessage();
        })
        .catch(error => {
            this.os.id      = false;
            this.os.title   = false;
        })
        .finally(() => {
            if ( !this.active )
            {
                if ( !this.rated )
                    this.loading = false;

                if ( this.rated )
                    this.rated = true;
            } else {
                this.active = true;
            }
                
        })
    },
    computed: {
        validation () {
            return this.obs.length > 1;
        }
    },
    methods: {
        onSubmit(evt) {
            evt.preventDefault();

            this.loading = true;
            
            const params = new URLSearchParams();
            params.append('usrfeedback',    this.rating);
            params.append('usrobservacao',  this.obs);

            axios({
                method: 'post',
                url: 'https://api.surgeonline.com.br/helpdesk-ff/os/' + this.os.uuid + '/evaluate/',
                data: params
            }).then(response =>
            {  
                this.showRatedMessage();
            })
            .catch(error => {
                if ( error.response.data.message == "Esta OS já foi avaliada" )
                    this.showRatedMessage();
                else
                    console.log( 'Erro no envio.' );
            })
        },
        showRatedMessage () {
            this.rated = true;
        }
    }
}
</script>
